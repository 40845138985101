import { DownOutlined, UpOutlined } from '@ant-design/icons';
import React, { useEffect, useRef } from 'react';
import theme from './TreeSearchField.scss';
import { useDispatch, useSelector } from 'react-redux';
import { NodeId } from '../../../../serverapi/api';
import { setMoveDirection, findNodeIdsBySearchStr } from '../../../../actions/navigatorTreeSearch.actions';
import { NavigatorTreeSearchSelector } from '../../../../selectors/navigatorTreeSearch.selectors';
import { Input, InputRef } from 'antd';
import messages from '../../messages/Navigator.messages';
import { useIntl } from 'react-intl';
import { TreeSelectors } from '../../../../selectors/tree.selectors';
import { NAVIGATOR_STRUCTURE } from '../../../../utils/consts';
import { TExpandTree, TTreeNodeWithLevel } from '../../../Tree/Tree.types';
import { useFlatTree } from '../../../../hooks/useFlatTree.hook';
import { TreeNode } from '../../../../models/tree.types';
import { ExpandedNodesSelector } from '@/selectors/expandedNodes.selectors';
import { Button } from '@/modules/UIKit/components/Button/Button.component';

export const TreeSearchField = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const inputRef: React.RefObject<InputRef> = useRef<InputRef>(null);

    const foundNodeIds: NodeId[] = useSelector(NavigatorTreeSearchSelector.getFoundedNodeIds);
    const isDisabledBtn: boolean = !foundNodeIds.length;
    const searchStr: string = useSelector(NavigatorTreeSearchSelector.getSearchString);
    const isTreeSearchActive: boolean = useSelector(NavigatorTreeSearchSelector.getIsSearchActive);
    const data: TreeNode[] = useSelector(TreeSelectors.treeNodesWithoutHidden);
    const expandTree: TExpandTree | undefined = useSelector(
        ExpandedNodesSelector.expandTreeByName(NAVIGATOR_STRUCTURE),
    );
    const flatData: TTreeNodeWithLevel[] = useFlatTree(data, expandTree);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(findNodeIdsBySearchStr(flatData, e.target.value));
    };

    const handleUpClick = () => {
        dispatch(setMoveDirection('up'));
    };

    const handleDownClick = () => {
        dispatch(setMoveDirection('down'));
    };

    useEffect(() => {
        if (isTreeSearchActive) {
            inputRef.current?.focus({
                cursor: 'all',
            });
        }
    }, [isTreeSearchActive]);

    const handleEnter = () => {
        dispatch(setMoveDirection('down'));
    };

    return (
        <div className={theme.treeSearchFieldContainer}>
            <div className={theme.inputContainer}>
                <Input
                    onChange={handleChange}
                    value={searchStr}
                    autoFocus
                    placeholder={`${intl.formatMessage(messages.toSearch)}...`}
                    ref={inputRef}
                    onPressEnter={handleEnter}
                />
            </div>
            <div>
                <Button
                    dataTest="search-bar_up-arrow_btn"
                    visualStyle={{type: "text"}}
                    size="small"
                    onClick={handleUpClick}
                    disabled={isDisabledBtn}
                >
                    <UpOutlined />
                </Button>
            </div>
            <div>
                <Button
                    dataTest="search-bar_down-arrow_btn"
                    visualStyle={{type: "text"}}
                    size="small"
                    onClick={handleDownClick}
                    disabled={isDisabledBtn}
                >
                    <DownOutlined />
                </Button>
            </div>
        </div>
    );
};
