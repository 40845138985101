import { Tooltip } from 'antd';
import React, { FC } from 'react';
import { Icon } from '../../UIKit/components/Icon/Icon.component';
import messages from './RowButtons.messages';
import icDelete from '../../../resources/icons/delete.svg';
import { useIntl } from 'react-intl';
import { openDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { getStore } from '../../../store';
import { Button } from '../../UIKit/components/Button/Button.component';

type TDeleteButton = {
    onDelete: (e?) => void;
    deleteQuestion?: string;
    dialogContent?: React.ReactNode;
    disabled?: boolean;
};

export const showDeleteConfirmation = (props: TDeleteButton) => {
    const store = getStore();
    const { dispatch } = store;
    dispatch(openDialog(DialogType.DELETE_CONFIRMATION, props));
};

const DeleteButton: FC<TDeleteButton> = (props) => (
    <Tooltip mouseLeaveDelay={0} title={useIntl().formatMessage(messages.delete)}>
        <Button
            disabled={props.disabled}
            dataTest="row-button_DELETE"
            visualStyle={{type: "text"}}
            size="small"
            onClick={() => showDeleteConfirmation(props)}
        >
            <Icon spriteSymbol={icDelete} />
        </Button>
    </Tooltip>
);

export default DeleteButton;
