import type { NodeId, Symbol } from '@/serverapi/api';
import type { TTreeNodeWithLevel } from '../../Tree.types';
import type { TreeNode } from '@/models/tree.types';
import { ExpandStatus } from '@/reducers/tree.reducer.types';
import React, { FC } from 'react';
import { TreeItemType } from '../../models/tree';
import { FILE_ICON_TYPES } from '../../TreeItemIcons/treeItemIcons';
import theme from './TreeItemRowContainer.scss';
import classnames from 'classnames';
import { compareNodeIds } from '@/utils/nodeId.utils';
import { useDispatch, useSelector } from 'react-redux';
import { TreeSelectors } from '@/selectors/tree.selectors';
import { TreeItem } from '../TreeItem/TreeItem.component';
import { treeItemMove, treeItemsSelectSeveral, treeItemsSelectSlice } from '@/actions/tree.actions';
import { NavigatorTreeSearchSelector } from '@/selectors/navigatorTreeSearch.selectors';
import { getSelectedSlice } from '@/modules/Navigator/components/NavigatorSymbols/utils';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { SelectedNodesSelector } from '@/selectors/selectedNodes.selectors';
import { SymbolSelectors } from '@/selectors/symbol.selectors';
import { LEVEL_PADDING, getFileType } from '../TreeItem/TreeItem.utils';
import { ExpandedStatusSelector } from '@/selectors/expandedStatus.selectors ';

type TTreeItemRowContainerProps = {
    rowData: TTreeNodeWithLevel;
    selectedFoundNodeId: NodeId | undefined;
    treeName: string;
    isDndEnabled: boolean;
    isNavigatorStructure: boolean;
    disableContextMenu: boolean | undefined;
    flatData: TTreeNodeWithLevel[];
    onSelect: (selectedTreeItem: TreeNode) => void;
};

export const TreeItemRowContainer: FC<TTreeItemRowContainerProps> = (props) => {
    const {
        selectedFoundNodeId,
        isNavigatorStructure,
        flatData,
        isDndEnabled,
        disableContextMenu,
        treeName,
        rowData,
        onSelect,
    } = props;
    const { nodeId, type, idSymbol, language, extension, deleted, level } = rowData;

    const dispatch = useDispatch();

    const connected = useSelector(TreeSelectors.connected);
    const connectedServerId = connected[0];
    const lastSelectedNode: TreeNode | undefined = useSelector(SelectedNodesSelector.getNode(treeName));
    const isSearchActive: boolean = useSelector(NavigatorTreeSearchSelector.getIsSearchActive);

    const expandStatus: ExpandStatus = useSelector(ExpandedStatusSelector.expandStatus(treeName, nodeId));
    const isItemSelected: boolean = useSelector(SelectedNodesSelector.isNodeSelected(nodeId, treeName));
    const isFoundedNodeSelected: boolean = useSelector(
        SelectedNodesSelector.isNodeSelected(selectedFoundNodeId, treeName),
    );

    const isAddFavoriteTree: boolean = treeName === DialogType.SELECT_TREE_ITEM_ADD_FAVORITE_DIALOG;
    const isApprovalCopyTree: boolean = treeName === DialogType.SELECT_TREE_ITEM_APPROVAL_DIALOG;
    const foundNodeIds: NodeId[] = useSelector(NavigatorTreeSearchSelector.getFoundedNodeIds);

    const symbol: Symbol | undefined = useSelector(SymbolSelectors.byNodeIdAndSymbolId(nodeId, idSymbol));

    const itemClassName = classnames(theme.item, {
        [theme.item_selected]: isItemSelected,
    });

    const style = {
        '--row-height': `${100}%`,
        paddingLeft: `${(level + 1) * LEVEL_PADDING}px`,
    } as React.CSSProperties;

    let specialIcon: string = '';
    if (symbol) {
        specialIcon = symbol ? symbol.icon : '';
    }

    if (type === TreeItemType.Script && language) {
        specialIcon = language;
    }
    if (type === TreeItemType.File && extension) {
        specialIcon = FILE_ICON_TYPES[getFileType(extension)];
    }

    const onDropTreeItem = (targetNodeId: NodeId, draggedNodeId: NodeId) => {
        dispatch(treeItemMove({ targetNodeId, draggedNodeId }));
    };

    const onSelectSlice = (selectedTreeItem) => dispatch(treeItemsSelectSlice(selectedTreeItem));
    const onSelectSeveral = (selectedTreeItem) => dispatch(treeItemsSelectSeveral(selectedTreeItem));

    const onSelectTreeItem = (event: React.MouseEvent<HTMLInputElement>) => {
        if (event.shiftKey) {
            onSelectSlice(getSelectedSlice(flatData, rowData, lastSelectedNode));
        } else if (event.ctrlKey || event.metaKey) {
            onSelectSeveral(rowData);
        } else {
            const submenuElements = document.getElementsByClassName('ant-dropdown-menu-submenu-active');
            // Чтобы не сбрасывсалось выделение с нескольких элементов при наждатии на подменю
            // Проверяем, является ли элемент меню выпадающим списком, если является, то выделение не сбрасываем
            if (!submenuElements.length) {
                onSelect(rowData);
            }
        }
    };

    const isFound =
        ((isNavigatorStructure && isSearchActive) || isApprovalCopyTree || isAddFavoriteTree) &&
        foundNodeIds.find((foundNodeId) => compareNodeIds(foundNodeId, nodeId)) &&
        !isItemSelected;

    const isSelectedFound =
        isNavigatorStructure && isSearchActive && compareNodeIds(nodeId, selectedFoundNodeId) && !isFoundedNodeSelected;

    const dataTest = `tree-menu_item${type === TreeItemType.Server ? '-server' : ''}${isFound ? '-found' : ''}${
        isSelectedFound ? '-selected' : ''
    }${deleted ? '-deleted' : ''}`;

    return (
        <div
            key={nodeId.id}
            id={`tree-item-selected_${isItemSelected}`}
            data-test={dataTest}
            onClick={onSelectTreeItem}
            style={style}
            className={classnames(
                itemClassName,
                {
                    [theme.item_collapsed]: expandStatus === ExpandStatus.CLOSED,
                },
                {
                    [theme.isFound]: isFound,
                },
                {
                    [theme.isSelectedFoundNode]: isSelectedFound,
                },
            )}
        >
            <TreeItem
                rowData={rowData}
                treeName={treeName}
                disableContextMenu={disableContextMenu}
                connectedServerId={connectedServerId}
                isDndEnabled={isDndEnabled}
                specialIcon={specialIcon}
                expandStatus={expandStatus}
                onDrop={onDropTreeItem}
            />
        </div>
    );
};
