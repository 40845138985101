import React, { useState } from 'react';
import { IntlShape } from 'react-intl';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import messages from './DeleteNodeDialog.messages';
import theme from './DeleteNodeDialog.component.scss';
import { v4 as uuid } from 'uuid';
import { TooltipTitle } from '../../TooltipTitle/TooltipTitle.component';

const DEFAULT_MAX_TABS_IN_WINDOW: number = 5;

type TShowToBeClosedTabsProps = {
    tabsToBeClosed: string[];
    intl: IntlShape;
};

export const ShowToBeClosedTabs = (props: TShowToBeClosedTabsProps): JSX.Element => {
    const { tabsToBeClosed, intl } = props;

    const [isShowMoreButton, setIsShowMoreButton] = useState<boolean>(
        tabsToBeClosed.length > DEFAULT_MAX_TABS_IN_WINDOW,
    );

    const showTabs: string[] = isShowMoreButton
        ? [...tabsToBeClosed.slice(0, DEFAULT_MAX_TABS_IN_WINDOW)]
        : tabsToBeClosed;

    return (
        <div className={theme.removableElements}>
            {showTabs.map((item: string) => (
                <div className={theme.tab} key={uuid()}>
                    <TooltipTitle data-test="close-tabs_tabs">
                        {item}
                    </TooltipTitle>
                </div>
            ))}
            {isShowMoreButton && (
                <Button
                    dataTest="close-tabs_more-button"
                    visualStyle={{type: "text"}}
                    size="small"
                    onClick={() => {
                        setIsShowMoreButton(false);
                    }}
                >
                    {intl.formatMessage(messages.more)}
                </Button>
            )}
        </div>
    );
};
