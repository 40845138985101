import React, { CSSProperties } from 'react';
import classnames from 'classnames';
import theme from './Button.scss';
import { Tooltip } from 'antd';
import { TButtonProps, TTextButtonStyle } from './Button.types';
import { Icon } from '@/modules/UIKit/components/Icon/Icon.component';

/**
 * ссылка на фигму (https://www.figma.com/design/QwNP21XezszK7TasNIoDQf/SDS-Design-KIT?node-id=9-167&t=aUiHP2EDE6s7uZY2-0)
 *
 * @param {'large' | 'normal' | 'small'} size большая, средняя или маленькая кнопка
 * @param {'primary' | 'secondary' | TTextButtonStyle} visualStyle сплошная заливка, простая кнопка без заливки, только текст кнопки без заливки и рамок (можно передать цвет)
 * @param {boolean | undefined} danger кнопка красного цвета
 * @param {React.ReactNode} children текст в кнопке
 * @param {boolean | undefined} disabled дизейбл кнопки
 * @param {string | undefined} key React key кнопки
 * @param {string | undefined} dataTest идентификатор для тестов
 * @param {string | undefined} tooltip текст подсказки
 * @param {"button" | "submit" | "reset" | undefined} type html тип кнопки
 * @param {boolean | undefined} autoFocus автофокус
 * @param {MouseEventHandler<HTMLButtonElement> | undefined} onClick функция, которая будет вызвана при нажатии на кнопку
 * @param {FocusEventHandler<HTMLButtonElement>} onBlur функция, которая будет вызвана при потере фокуса
 * @param {TSpriteSymbol | undefined} icon svg иконка.
 * @param {number | undefined} width ширина конпки в px.
 */

export const Button = (props: TButtonProps) => {
    const {
        children,
        danger,
        size = 'normal',
        visualStyle = 'secondary',
        disabled,
        key,
        dataTest,
        tooltip,
        type,
        autoFocus,
        onClick,
        onBlur,
        icon,
        width,
    } = props;

    const buttonClass: string = classnames({
        [theme.button]: !disabled && visualStyle === 'secondary',
        [theme.buttonFilled]: !disabled && visualStyle === 'primary',
        [theme.textOnly]: !disabled && (visualStyle as TTextButtonStyle)?.type === 'text',
        [theme.buttonDisabled]: disabled && visualStyle === 'secondary',
        [theme.buttonFilledDisabled]: disabled && visualStyle === 'primary',
        [theme.textOnlyDisabled]: disabled && (visualStyle as TTextButtonStyle)?.type === 'text',
        [theme.big]: size === 'large',
        [theme.middle]: size === 'normal',
        [theme.small]: size === 'small',
        [theme.red]: danger,
        [theme.white]: (visualStyle as TTextButtonStyle)?.color === 'white',
        [theme.iconButton]: !('children' in props),
    });

    const buttonStyle: CSSProperties = { width };

    return (
        <Tooltip mouseLeaveDelay={0} title={tooltip}>
            <button
                type={type || 'button'}
                autoFocus={autoFocus}
                style={buttonStyle}
                className={buttonClass}
                key={key}
                onClick={!disabled ? onClick : undefined}
                onBlur={onBlur}
                data-test={dataTest}
            >
                <Icon className={theme.icon} spriteSymbol={icon} />
                {children}
            </button>
        </Tooltip>
    );
};
