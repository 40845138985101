import { TRootState } from '../../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { NodeId } from '../../serverapi/api';
import { TMatrixState } from '../../reducers/entities/matrix.reducer.types';

const matrixStateSelector = (state: TRootState): TMatrixState => state.matrix;

export namespace MatrixSelectors {
    export const byId = (nodeId: NodeId) => createSelector(matrixStateSelector, (state) => state.get(nodeId));

    export const isMatrixAutofilled = (nodeId: NodeId) =>
        createSelector<TRootState, TMatrixState, boolean>(
            matrixStateSelector,
            (state) => !!state.get(nodeId)?.data2?.cellSettings.isAutomatic,
        );
}
