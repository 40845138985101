import React, { FC, useEffect } from 'react';
import { AutoSizer, Dimensions, List, CellMeasurerCache, CellMeasurer, ListRowProps } from 'react-virtualized';
import theme from './Tree.scss';
import 'react-virtualized/styles.css';
import { NodeId } from '../../../../serverapi/api';
import { compareNodeIds } from '../../../../utils/nodeId.utils';
import { TExpandTree, TTreeFullProps, TTreeNodeWithLevel } from '../../Tree.types';
import { useDispatch, useSelector } from 'react-redux';
import { getScrolledNode } from '../../../../selectors/tree.selectors';
import { treeItemScroll, treeItemSelect } from '../../../../actions/tree.actions';
import { NavigatorTreeSearchSelector } from '../../../../selectors/navigatorTreeSearch.selectors';
import { changeFoundNodeIdsBySearchStr } from '../../../../actions/navigatorTreeSearch.actions';
import { NAVIGATOR_STRUCTURE } from '../../../../utils/consts';
import { useFlatTree } from '../../../../hooks/useFlatTree.hook';
import { TreeItemRowContainer } from '../TreeItemRowContainer/TreeItemRowContainer.component';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { ExpandedNodesSelector } from '@/selectors/expandedNodes.selectors';
import { TreeNode } from '@/models/tree.types';
import { allowedNodeSelection } from '../TreeItem/TreeItem.utils';

const ROW_HEIGHT = 24;

const cache = new CellMeasurerCache({
    fixedHeight: true,
    defaultHeight: 28,
    minHeight: 28,
    defaultWidth: 205,
});

export const Tree: FC<TTreeFullProps> = (props) => {
    const {
        data,
        treeName,
        disableContextMenu,
        isDndEnabled = false,
        includeFilterForSelect,
        excludeFilterForSelect,
    } = props;
    const dispatch = useDispatch();
    const scrolledNodeId: NodeId | undefined = useSelector(getScrolledNode);
    const expandTree: TExpandTree | undefined = useSelector(ExpandedNodesSelector.expandTreeByName(treeName));
    const flatData: TTreeNodeWithLevel[] = useFlatTree(data, expandTree);
    const selectedFoundNodeId: NodeId | undefined = useSelector(NavigatorTreeSearchSelector.getSelectedFoundNodeId);
    const isNavigatorStructure: boolean = treeName === NAVIGATOR_STRUCTURE;
    const isApprovalCopyTree: boolean = treeName === DialogType.SELECT_TREE_ITEM_APPROVAL_DIALOG;
    const isAddFavoriteTree: boolean = treeName === DialogType.SELECT_TREE_ITEM_ADD_FAVORITE_DIALOG;

    useEffect(() => {
        if (isNavigatorStructure || isApprovalCopyTree || isAddFavoriteTree) {
            dispatch(changeFoundNodeIdsBySearchStr(flatData));
        }
    }, [flatData]);

    useEffect(() => {
        if (
            isNavigatorStructure &&
            selectedFoundNodeId &&
            flatData.some((treeItemData) => compareNodeIds(treeItemData.nodeId, selectedFoundNodeId))
        )
            dispatch(treeItemScroll(selectedFoundNodeId));
            
    }, [selectedFoundNodeId]);

    useEffect(() => {
        if (scrolledNodeId) {
            dispatch(treeItemScroll(undefined));
        }
    }, [scrolledNodeId]);

    const onSelectHandler = (selectedTreeItem: TreeNode) => {
        if (includeFilterForSelect || excludeFilterForSelect) {
            if (allowedNodeSelection(selectedTreeItem, includeFilterForSelect, excludeFilterForSelect))
                dispatch(treeItemSelect(selectedTreeItem, treeName));
        } else {
            dispatch(treeItemSelect(selectedTreeItem, treeName));
        }
    };

    const measureRowRenderer = ({ index, style, key, parent }: ListRowProps) => {
        const node: TTreeNodeWithLevel = flatData[index];

        const rowStyle: React.CSSProperties = {
            ...style,
            gridColumn: 1,
            gridRow: 1,
            position: 'relative',
        };

        return (
            <CellMeasurer cache={cache} columnIndex={0} key={`${key}_${node.deleted}`} rowIndex={index} parent={parent}>
                <div style={rowStyle}>
                    <TreeItemRowContainer
                        disableContextMenu={disableContextMenu}
                        flatData={flatData}
                        rowData={node}
                        isDndEnabled={isDndEnabled}
                        isNavigatorStructure={isNavigatorStructure}
                        selectedFoundNodeId={selectedFoundNodeId}
                        treeName={treeName}
                        onSelect={onSelectHandler}
                    />
                </div>
            </CellMeasurer>
        );
    };

    const scrolledIndex = scrolledNodeId && flatData.findIndex((el) => compareNodeIds(el.nodeId, scrolledNodeId));

    return (
        <div className={theme.container}>
            <AutoSizer>
                {({ height, width }: Dimensions) => (
                    <List
                        deferredMeasurementCache={cache}
                        height={height}
                        overscanRowCount={0}
                        rowCount={flatData.length}
                        rowHeight={ROW_HEIGHT}
                        className={theme.list}
                        rowRenderer={measureRowRenderer}
                        width={width}
                        scrollToIndex={scrolledIndex && scrolledIndex >= 0 ? scrolledIndex : undefined}
                        scrollToAlignment="center"
                    />
                )}
            </AutoSizer>
        </div>
    );
};
