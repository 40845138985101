import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import theme from './NewMatrixEditor.scss';
import { Table } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog, openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { TMatrixTabType } from '@/models/tab.types';
import { changeMatrixProperties } from '@/actions/entities/matrix.actions';
import { InternationalString, MatrixCellBPM8764, MatrixDataBPM8764, MatrixLane, MatrixNode } from '@/serverapi/api';
import { MatrixSelectors } from '@/selectors/entities/matrix.selectors';
import { getCurrentLocale } from '@/selectors/locale.selectors';
import { NewMatrixMainHeader } from './NewMatrixMainHeader/NewMatrixMainHeader.component';
import { LocalesService } from '@/services/LocalesService';
import { TreeSelectors, getTreeItems } from '@/selectors/tree.selectors';
import { SymbolSelectors } from '@/selectors/symbol.selectors';
import { EditorMode } from '@/models/editorMode';
import { cloneDeep } from 'lodash-es';
import {
    completHeadersArray,
    fillCellsWithSameLinkedNodeIds,
    getCellIdByRowIdAndColId,
    getCells,
    getRowIdAndColIdByCellId,
    getSelectedLanesWithChildren,
} from '../NewMatrix.utils';
import { HeaderResizeDirections, HeaderType, THeaderResizeData, TResizeData } from './NewMatrix.types';
import { NewMatrixSelectors } from '@/selectors/entities/newMatrix.selectors';
import {
    matrixClearSelectedCells,
    matrixPastObjects,
    newMatrixSaveRequest,
} from '@/actions/entities/newMatrix.actions';
import { useAutoSave } from '@/hooks/useAutoSave';
import { NewMatrixHeaderCell } from './NewMatrixHeaderCell.component';
import { NewMatrixCell } from './NewMatrixCell.component';
import { throttle } from 'lodash-es';
import cn from 'classnames';
import { DialogsSelectors } from '@/selectors/dialogs.selectors';
import { MIN_HEADER_SIZE, MIN_LINE_SIZE } from '../NewMatrix.constants';

type TNewMatrixEditorProps = {
    tab: TMatrixTabType;
};

export const NewMatrixEditor: FC<TNewMatrixEditorProps> = ({ tab: { nodeId, mode } }) => {
    const [isRowDrag, setIsRowDrag] = useState<boolean>(false);

    const [resizeData, setResizeData] = useState<TResizeData>({ start: 0, size: 0, id: '' });
    const [isRowResizing, setIsRowResizing] = useState<boolean>(false);
    const [isColResizing, setIsColResizing] = useState<boolean>(false);

    const [headerResizeData, setHeaderResizeData] = useState<THeaderResizeData>({
        x: 0,
        width: 0,
        height: 0,
        y: 0,
        direction: HeaderResizeDirections.both,
    });
    const [isHeaderResizing, setIsHeaderResizing] = useState<boolean>(false);

    const isVisibleDialog: boolean = useSelector(DialogsSelectors.isVisibleDialog);
    const selectedHeaderCells = useSelector(NewMatrixSelectors.getSelectedHeaderCells(nodeId));
    const selectedCells = useSelector(NewMatrixSelectors.getSelectedCells(nodeId));
    const objectDefinitions = useSelector(getTreeItems(nodeId.serverId, nodeId.repositoryId));
    const currentLocale = useSelector(getCurrentLocale);
    const symbols = useSelector(SymbolSelectors.all);
    const presetId = useSelector(TreeSelectors.presetById(nodeId));
    const matrix: MatrixNode | undefined = useSelector(MatrixSelectors.byId(nodeId));
    const matrixData: MatrixDataBPM8764 | undefined = cloneDeep(matrix?.data2);
    const colsHeaders = matrixData?.columns || [];
    const rowsHeaders = matrixData?.rows || [];
    const columnHeaderHeight = matrixData?.columnHeaderHeight || MIN_HEADER_SIZE;
    const rowHeaderWidth = matrixData?.rowHeaderWidth || MIN_HEADER_SIZE;
    const cells = getCells(matrixData);
    const isReadMode = mode === EditorMode.Read;

    const dispatch = useDispatch();

    useEffect(() => {
        const mouseMoveHandler = throttle((e: MouseEvent) => {
            if (!matrixData) return;

            if (isColResizing) {
                const currentCol = colsHeaders.find((lane) => lane.id === resizeData.id);
                if (currentCol) {
                    currentCol.headerSize = Math.max(resizeData.size + e.clientX - resizeData.start, MIN_LINE_SIZE);
                    updateMatrixData(matrixData);
                }
            }

            if (isRowResizing) {
                const currentRow = rowsHeaders.find((lane) => lane.id === resizeData.id);
                if (currentRow) {
                    currentRow.headerSize = Math.max(resizeData.size + e.clientY - resizeData.start, MIN_LINE_SIZE);
                    updateMatrixData(matrixData);
                }
            }

            if (isHeaderResizing) {
                if (
                    headerResizeData.direction === HeaderResizeDirections.horizontal ||
                    headerResizeData.direction === HeaderResizeDirections.both
                ) {
                    matrixData.rowHeaderWidth = Math.max(
                        headerResizeData.width + e.clientX - headerResizeData.x,
                        MIN_HEADER_SIZE,
                    );
                }
                if (
                    headerResizeData.direction === HeaderResizeDirections.vertical ||
                    headerResizeData.direction === HeaderResizeDirections.both
                ) {
                    matrixData.columnHeaderHeight = Math.max(
                        headerResizeData.height + e.clientY - headerResizeData.y,
                        MIN_HEADER_SIZE,
                    );
                }
                updateMatrixData(matrixData);
            }
        }, 50);
        document.addEventListener('mousemove', mouseMoveHandler);

        const mouseUpHandler = () => {
            setIsRowResizing(false);
            setIsColResizing(false);
            setIsHeaderResizing(false);
        };
        document.addEventListener('mouseup', mouseUpHandler);

        return () => {
            document.removeEventListener('mouseup', mouseUpHandler);
            document.removeEventListener('mousemove', mouseMoveHandler);
        };
    }, [isRowResizing, isColResizing, resizeData, isHeaderResizing, headerResizeData]);

    const saveMatrixAction = () => {
        dispatch(newMatrixSaveRequest(nodeId));
    };

    useAutoSave({ isEditMode: !isReadMode, nodeId, callback: saveMatrixAction });

    const updateMatrixData = (data: MatrixDataBPM8764) => {
        if (matrix) {
            completHeadersArray(data.columns);
            completHeadersArray(data.rows);

            const newMatrix = { ...matrix, data2: data };
            dispatch(changeMatrixProperties(nodeId, newMatrix));
        }
    };

    const clearCellsSelectionAction = () => {
        dispatch(matrixClearSelectedCells(nodeId));
    };

    useEffect(() => {
        if (isReadMode) {
            clearCellsSelectionAction();

            return;
        }

        const keyDownHandler = (event: KeyboardEvent) => {
            if (isVisibleDialog) return;

            // ctrl + v
            if (event.ctrlKey && event.code === 'KeyV') {
                dispatch(matrixPastObjects(nodeId));
            }
            // Delete col/row
            if (event.key === 'Delete' && matrixData && selectedHeaderCells.ids.length !== 0) {
                const selectedLanesWithChildren: MatrixLane[] = getSelectedLanesWithChildren(
                    selectedHeaderCells.ids,
                    [...colsHeaders, ...rowsHeaders],
                    true,
                );
                const selectedIdsWithChildren: string[] = selectedLanesWithChildren.map((lane) => lane.id);
                matrixData.columns = colsHeaders.filter((col) => !selectedIdsWithChildren.includes(col.id)) || [];
                matrixData.rows = rowsHeaders.filter((row) => !selectedIdsWithChildren.includes(row.id)) || [];
                matrixData.cells =
                    cells.filter(
                        ({ columnId, rowId }) =>
                            !selectedIdsWithChildren.some((cellId) => cellId === columnId || cellId === rowId),
                    ) || [];
                clearCellsSelectionAction();
                updateMatrixData(matrixData);
            }

            // Delete cell
            if (event.key === 'Delete' && matrixData && selectedCells.length !== 0) {
                selectedCells.forEach((selectedCellId) => {
                    const { colId, rowId } = getRowIdAndColIdByCellId(selectedCellId);
                    const currentCell: MatrixCellBPM8764 | undefined = cells.find(
                        ({ rowId: cellRowId, columnId: cellColId }) => cellRowId === rowId && cellColId === colId,
                    );
                    if (!currentCell) return;

                    currentCell.styleIds = [];
                    fillCellsWithSameLinkedNodeIds(currentCell, matrixData);
                });
                updateMatrixData(matrixData);
            }
        };
        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [selectedHeaderCells, isReadMode, selectedCells, mode]);

    const openRenameDialog = (onSubmit: (newName: InternationalString) => void, initName?: InternationalString) => {
        if (isReadMode) return;

        const onClose = () => dispatch(closeDialog(DialogType.MATRIX_HEADER_RENAME_DIALOG));

        dispatch(
            openDialog(DialogType.MATRIX_HEADER_RENAME_DIALOG, {
                initName,
                onClose,
                onSubmit: (newName: InternationalString) => {
                    onSubmit(newName);
                    onClose();
                },
            }),
        );
    };

    const checkIsCellSelectable = (rowIndex: number, colIndex: number, rowText: string, colText: string) => {
        return (
            !!rowText &&
            !!colText &&
            rowsHeaders.findLastIndex((row) => row.text) >= rowIndex &&
            colsHeaders.findLastIndex((col) => col.text) >= colIndex &&
            !isReadMode
        );
    };

    const getHeaderCellIcon = (symbolId: string = '') => {
        const symbol = symbols.get({ serverId: nodeId.serverId, presetId, symbolId });
        return symbol?.icon || '';
    };

    const matrixContainerRef = useRef<HTMLDivElement>(null);

    return (
        <div ref={matrixContainerRef} className={theme.container} data-test="matrix-editor_container">
            {
                // @ts-ignore
                <Table definition className={theme.table}>
                    <Table.Header>
                        <Table.Row className={theme.columnsRowContainer}>
                            <NewMatrixMainHeader
                                currentLocale={currentLocale}
                                data={matrixData}
                                isReadMode={isReadMode}
                                saveMatrixData={updateMatrixData}
                                openRenameDialog={openRenameDialog}
                                setIsHeaderResizing={setIsHeaderResizing}
                                setHeaderResizeData={setHeaderResizeData}
                            />
                            <div
                                className={cn({ [theme.colResizer]: !isReadMode })}
                                onDragStart={(e) => {
                                    e.preventDefault();
                                }}
                                onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => {
                                    if (isReadMode) return;

                                    setIsHeaderResizing(true);
                                    setHeaderResizeData({
                                        height: columnHeaderHeight,
                                        width: rowHeaderWidth,
                                        x: e.clientX,
                                        y: e.clientY,
                                        direction: HeaderResizeDirections.horizontal,
                                    });
                                }}
                            />
                            {colsHeaders.map(({ id, text, symbolId, linkedNodeId, headerSize }, colIndex) => {
                                if (!matrixData) return undefined;
                                const localeText = LocalesService.internationalStringToString(text, currentLocale);
                                const icon = getHeaderCellIcon(symbolId);

                                return (
                                    <Fragment key={id}>
                                        <NewMatrixHeaderCell
                                            id={id}
                                            key={id}
                                            index={colIndex}
                                            headerSize={headerSize || MIN_LINE_SIZE}
                                            type={HeaderType.column}
                                            text={localeText}
                                            icon={icon}
                                            nodeId={nodeId}
                                            linkedNodeId={linkedNodeId}
                                            matrixData={matrixData}
                                            colsHeaders={colsHeaders}
                                            rowsHeaders={rowsHeaders}
                                            isReadMode={isReadMode}
                                            isRowDrag={isRowDrag}
                                            objectDefinitions={objectDefinitions}
                                            selectedHeaderCells={selectedHeaderCells}
                                            matrixContainerRef={matrixContainerRef}
                                            openRenameDialog={openRenameDialog}
                                            setIsRowDrag={setIsRowDrag}
                                            updateMatrixData={updateMatrixData}
                                        />
                                        {
                                            <div
                                                className={cn({ [theme.colResizer]: !isReadMode })}
                                                onDragStart={(e) => {
                                                    e.preventDefault();
                                                }}
                                                onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => {
                                                    if (isReadMode) return;

                                                    setIsColResizing(true);
                                                    setResizeData({
                                                        start: e.clientX,
                                                        size: headerSize || MIN_LINE_SIZE,
                                                        id: id,
                                                    });
                                                }}
                                            />
                                        }
                                    </Fragment>
                                );
                            })}
                        </Table.Row>
                        <div
                            style={{ width: `100%` }}
                            className={cn({ [theme.rowResizer]: !isReadMode })}
                            onDragStart={(e) => {
                                e.preventDefault();
                            }}
                            onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => {
                                if (isReadMode) return;

                                setIsHeaderResizing(true);
                                setHeaderResizeData({
                                    height: columnHeaderHeight,
                                    width: rowHeaderWidth,
                                    x: e.clientX,
                                    y: e.clientY,
                                    direction: HeaderResizeDirections.vertical,
                                });
                            }}
                        />
                    </Table.Header>

                    <Table.Body>
                        {rowsHeaders.map(
                            (
                                {
                                    id: rowId,
                                    text: rowText,
                                    symbolId: rowSymbolId,
                                    linkedNodeId,
                                    headerSize: rowHeight,
                                },
                                rowIndex,
                            ) => {
                                const rowLocaleText = LocalesService.internationalStringToString(
                                    rowText,
                                    currentLocale,
                                );
                                const rowIcon = getHeaderCellIcon(rowSymbolId);
                                return (
                                    <Fragment key={rowId}>
                                        <Table.Row style={{ height: `${rowHeight || MIN_LINE_SIZE}px` }}>
                                            {matrixData && (
                                                <NewMatrixHeaderCell
                                                    id={rowId}
                                                    index={rowIndex}
                                                    headerSize={rowHeight || MIN_LINE_SIZE}
                                                    type={HeaderType.row}
                                                    text={rowLocaleText}
                                                    icon={rowIcon}
                                                    nodeId={nodeId}
                                                    linkedNodeId={linkedNodeId}
                                                    matrixData={matrixData}
                                                    colsHeaders={colsHeaders}
                                                    rowsHeaders={rowsHeaders}
                                                    isReadMode={isReadMode}
                                                    isRowDrag={isRowDrag}
                                                    objectDefinitions={objectDefinitions}
                                                    selectedHeaderCells={selectedHeaderCells}
                                                    matrixContainerRef={matrixContainerRef}
                                                    openRenameDialog={openRenameDialog}
                                                    setIsRowDrag={setIsRowDrag}
                                                    updateMatrixData={updateMatrixData}
                                                />
                                            )}
                                            <div
                                                className={cn({ [theme.colResizer]: !isReadMode })}
                                                onDragStart={(e) => {
                                                    e.preventDefault();
                                                }}
                                                onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => {
                                                    if (isReadMode) return;

                                                    setIsHeaderResizing(true);
                                                    setHeaderResizeData({
                                                        height: columnHeaderHeight,
                                                        width: rowHeaderWidth,
                                                        x: e.clientX,
                                                        y: e.clientY,
                                                        direction: HeaderResizeDirections.horizontal,
                                                    });
                                                }}
                                            />
                                            {colsHeaders.map(
                                                (
                                                    {
                                                        id: colId,
                                                        text: colText,
                                                        headerSize: colWidth,
                                                        symbolId: colSymbolId,
                                                    },
                                                    colIndex,
                                                ) => {
                                                    const colLocaleText = LocalesService.internationalStringToString(
                                                        colText,
                                                        currentLocale,
                                                    );
                                                    const colIcon = getHeaderCellIcon(colSymbolId);
                                                    const cellId = getCellIdByRowIdAndColId(rowId, colId);
                                                    const isCellSelectable = checkIsCellSelectable(
                                                        rowIndex,
                                                        colIndex,
                                                        rowLocaleText,
                                                        colLocaleText,
                                                    );
                                                    const currentCell = cells.find(
                                                        ({ rowId: cellRowId, columnId: cellColId }) =>
                                                            cellRowId === rowId && cellColId === colId,
                                                    );
                                                    if (!matrixData || !currentCell) return undefined;

                                                    return (
                                                        <NewMatrixCell
                                                            key={cellId}
                                                            cellId={cellId}
                                                            cell={currentCell}
                                                            colId={colId}
                                                            colIndex={colIndex}
                                                            rowId={rowId}
                                                            rowIndex={rowIndex}
                                                            colsHeaders={colsHeaders}
                                                            rowsHeaders={rowsHeaders}
                                                            colIcon={colIcon}
                                                            rowIcon={rowIcon}
                                                            width={colWidth || MIN_LINE_SIZE}
                                                            isCellSelectable={isCellSelectable}
                                                            isReadMode={isReadMode}
                                                            matrixData={matrixData}
                                                            nodeId={nodeId}
                                                            selectedCells={selectedCells}
                                                            selectedHeaderCells={selectedHeaderCells}
                                                            currentLocale={currentLocale}
                                                            updateMatrixData={updateMatrixData}
                                                        />
                                                    );
                                                },
                                            )}
                                        </Table.Row>
                                        <div
                                            style={{ width: `${rowHeaderWidth}px` }}
                                            className={cn({ [theme.rowResizer]: !isReadMode })}
                                            onDragStart={(e) => {
                                                e.preventDefault();
                                            }}
                                            onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => {
                                                if (isReadMode) return;

                                                setIsRowResizing(true);
                                                setResizeData({
                                                    start: e.clientY,
                                                    size: rowHeight || MIN_LINE_SIZE,
                                                    id: rowId,
                                                });
                                            }}
                                        />
                                    </Fragment>
                                );
                            },
                        )}
                    </Table.Body>
                </Table>
            }
        </div>
    );
};
