import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { NodeId } from '@/serverapi/api';
import messages from './StatisticsTab.messages';
import { nodeStatisticsDelete, nodeStatisticsLoad } from '@/actions/nodeStatistics.actions';
import { NodeStatisticsSelectors } from '@/selectors/nodeStatistics.selector';
import { TTableData } from './StatisticsTab.types';
import { Table } from 'antd';
import { Spinner } from '@/modules/Spinner/Spinner.component';

type TStatisticsTab = {
    nodeId: NodeId;
};

type TColumnData = {
    title: string;
    dataIndex: string;
    key: string;
    render?: (name: string) => JSX.Element;
};

export const StatisticsTab: FC<TStatisticsTab> = ({ nodeId }) => {
    const dispatch = useDispatch();
    const intl = useIntl();

    const dataSource: TTableData[] | undefined = useSelector(NodeStatisticsSelectors.nodeStatisticsTableData(nodeId));

    useEffect(() => {
        dispatch(nodeStatisticsLoad(nodeId));

        return () => {
            dispatch(nodeStatisticsDelete(nodeId));
        };
    }, []);

    const columns: TColumnData[] = [
        {
            title: '',
            dataIndex: 'name',
            key: 'name',
            render: (name: string) => <div>{intl.formatMessage(messages[name] || '')}</div>,
        },
        {
            title: intl.formatMessage(messages.count),
            dataIndex: 'count',
            key: 'count',
        },
        {
            title: intl.formatMessage(messages.deletedCount),
            dataIndex: 'deletedCount',
            key: 'deletedCount',
        },
    ];

    return (
        <Spinner loading={!dataSource}>
            <Table dataSource={dataSource} columns={columns} size="middle" bordered pagination={false} />
        </Spinner>
    );
};

