import theme from './MatrixEditor.component.scss';
import { injectIntl } from 'react-intl';
import React, { ReactNode } from 'react';
import { Table } from 'semantic-ui-react';
import {
    CustomMatrixState,
    IconMatrixState,
    MatrixCell,
    MatrixHeader,
    MatrixNode,
    MatrixState,
    NodeMatrixHeader,
    SimpleMatrixHeader,
    UserIconMatrixState,
} from '../../../serverapi/api';
import { Button, Tooltip } from 'antd';
import { matrixService } from '../../../services/MatrixService';
import { CheckOutlined, CloseOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { PictureSymbolConstants } from '../../../models/pictureSymbolConstants';
import { MatrixHeaderEnum } from '../../MatrixSettingsDialog/matrixHeaderEnum';
import { EditorMode } from '../../../models/editorMode';
import { debounce } from 'lodash-es';
import { CommentsPanelContainer } from '../../Comments/containers/CommentsPanel.container';
import SplitPane from 'react-split-pane';
import { TreeNode } from '../../../models/tree.types';
import { LOCK_TIMEOUT } from '../../../utils/consts';
import electron, { remote } from '../../../electron';
import { compareNodeIds } from '../../../utils/nodeId.utils';
import { TooltipTitle } from '../../TooltipTitle/TooltipTitle.component';
import { TMatrixEditorProps } from './MatrixEditor.types';

type TMatrixEditorState = {
    matrix: MatrixNode;
    undoManager: {
        undo: boolean;
        redo: boolean;
    };
};

class MatrixEditor extends React.Component<TMatrixEditorProps> {
    state: TMatrixEditorState;

    isReadMode: boolean = true;

    debounceOnChange: Function;
    private lockIntervalID: number = 0;

    constructor(props: TMatrixEditorProps) {
        super(props);
        this.debounceOnChange = debounce(
            (matrixNode: MatrixNode) => this.props.onSave(matrixNode.nodeId, matrixNode.data),
            1500,
        );
    }

    componentDidMount() {
        this.lockIntervalID = Number(
            setInterval(() => this.props.mode !== EditorMode.Read && this.props.onLock(), LOCK_TIMEOUT),
        );
        if (electron) {
            const mainWindow = remote.getCurrentWindow();
            mainWindow.on('close', () => {
                this.props.onUnlock();
            });
        }
    }

    shouldComponentUpdate(nextProps: TMatrixEditorProps) {
        if (nextProps.activeKey.id !== nextProps.nodeId.id) {
            return false;
        }

        return true;
    }

    componentWillUnmount() {
        clearInterval(this.lockIntervalID);
    }

    getCellsByRowIndex(rowNumber: number): ReactNode[] {
        const {
            matrix: { data },
        } = this.props;
        if (!data) {
            return [];
        }
        const cells: MatrixCell[] = data.cells.filter((t: MatrixCell) => t.rowNumber === rowNumber);
        const result: ReactNode[] = data.columns
            .sort((a: MatrixHeader, b: MatrixHeader) => a.orderNumber - b.orderNumber)
            .map((currColumn: MatrixHeader, index: number) => {
                const cell: MatrixCell | undefined = cells.find((t) => t.colNumber === currColumn.orderNumber);
                if (!cell) {
                    return this.renderEmptyCell(rowNumber, currColumn.orderNumber, index);
                }
                const state: MatrixState = matrixService().getStateById(data, cell.stateId);
                if (state.type === 'CUSTOM') {
                    const customMatrixState: CustomMatrixState = state as CustomMatrixState;

                    return this.renderCustomCell(rowNumber, currColumn.orderNumber, customMatrixState, index);
                }
                if (state.type === 'ICON') {
                    const iconMatrixState: IconMatrixState = state as IconMatrixState;

                    return this.renderIconCell(rowNumber, currColumn.orderNumber, iconMatrixState, index);
                }
                if (state.type === 'USER_ICON') {
                    const userIconMatrixState: UserIconMatrixState = state as UserIconMatrixState;

                    return this.renderUserIconCell(rowNumber, currColumn.orderNumber, userIconMatrixState, index);
                }
                throw new Error('Such type of state not implemented yet');
            });

        return result;
    }

    switchCell(rowNumber: number, colNumber: number) {
        const { matrix } = this.props;
        matrix.data = matrixService().switchCell(matrix.data, rowNumber, colNumber);
        this.debounceOnChange(matrix);
        this.forceUpdate();
    }

    renderEmptyCell(rowNumber: number, colNumber: number, index: number): ReactNode {
        return (
            <Table.Cell key={index}>
                <span className={this.isReadMode ? theme.disabledButton : ''}>
                    <Button
                        className={theme.switcher}
                        ghost
                        onClick={() => {
                            this.switchCell(rowNumber, colNumber);
                        }}
                    >
                        <span />
                    </Button>
                </span>
            </Table.Cell>
        );
    }

    renderCustomCell(rowNumber: number, colNumber: number, state: CustomMatrixState, index: number): ReactNode {
        return (
            <Table.Cell key={index}>
                <Tooltip mouseLeaveDelay={0} title={state.description}>
                    <span className={this.isReadMode ? theme.disabledButton : ''}>
                        <Button
                            className={theme.switcher}
                            ghost
                            onClick={() => {
                                this.switchCell(rowNumber, colNumber);
                            }}
                        >
                            <span style={{ color: state.color }}>{state.text}</span>
                        </Button>
                    </span>
                </Tooltip>
            </Table.Cell>
        );
    }

    renderIconCell(rowNumber: number, colNumber: number, state: IconMatrixState, index: number): ReactNode {
        return (
            <Table.Cell key={index}>
                <Tooltip mouseLeaveDelay={0} title={state.description}>
                    <span className={this.isReadMode ? theme.disabledButton : ''}>
                        <Button
                            className={theme.switcher}
                            ghost
                            onClick={() => {
                                this.switchCell(rowNumber, colNumber);
                            }}
                        >
                            {this.renderIcon(state)}
                        </Button>
                    </span>
                </Tooltip>
            </Table.Cell>
        );
    }

    renderUserIconCell(rowNumber: number, colNumber: number, state: UserIconMatrixState, index: number): ReactNode {
        const { serverUrl } = this.props;

        return (
            <Table.Cell key={index}>
                <Tooltip mouseLeaveDelay={0} title={state.description}>
                    <span className={this.isReadMode ? theme.disabledButton : ''}>
                        <Button
                            className={theme.switcher}
                            ghost
                            onClick={() => {
                                this.switchCell(rowNumber, colNumber);
                            }}
                        >
                            <div>
                                <img src={`${serverUrl}/${PictureSymbolConstants.DOWNLOAD_LINK}/${state.iconPath}/`} />
                            </div>
                        </Button>
                    </span>
                </Tooltip>
            </Table.Cell>
        );
    }

    renderIcon = (iconState: IconMatrixState): ReactNode => {
        if (iconState.icon === 'PLUS') {
            return (
                <div>
                    <PlusOutlined style={{ color: iconState.color }} />
                </div>
            );
        }
        if (iconState.icon === 'MINUS') {
            return (
                <div>
                    <MinusOutlined style={{ color: iconState.color }} />
                </div>
            );
        }
        if (iconState.icon === 'CHECK') {
            return (
                <div>
                    <CheckOutlined style={{ color: iconState.color }} />
                </div>
            );
        }
        if (iconState.icon === 'CROSS') {
            return (
                <div>
                    <CloseOutlined style={{ color: iconState.color }} />
                </div>
            );
        }
        throw new Error('Such state icon type not implemented yet');
    };

    renderHeader(header: MatrixHeader, rowColType: MatrixHeaderEnum): ReactNode {
        if (header.type === 'SIMPLE') {
            const simpleHeader: SimpleMatrixHeader = header as SimpleMatrixHeader;

            return (
                <>
                    <TooltipTitle vertical={rowColType === MatrixHeaderEnum.ColumnHeader}>
                        {simpleHeader.title}
                    </TooltipTitle>
                </>
            );
        }
        if (header.type === 'NODE') {
            const nodeHeader: NodeMatrixHeader = header as NodeMatrixHeader;

            const e: TreeNode = this.props.treeItemsById[nodeHeader.nodeId];
            const title = e && e.name;

            return (
                <>
                    <TooltipTitle vertical={rowColType === MatrixHeaderEnum.ColumnHeader}>{title}</TooltipTitle>
                </>
            );
        }
        throw new Error('No such matrix header implementation');
    }

    render() {
        const {
            nodeId,
            mode,
            matrix: { data },
            commentsEnabledSchemesIds,
            // checkFocus,
            // onFocus,
        } = this.props;
        this.isReadMode = mode === EditorMode.Read;

        const isShowCommentsPanel = commentsEnabledSchemesIds.some((id) => compareNodeIds(id, nodeId));

        const comments = (
            <div className={isShowCommentsPanel ? theme.commentsPanel : theme.hideCommentsPanel}>
                <CommentsPanelContainer />
            </div>
        );

        return (
            <section className={theme.container}>
                {
                    // @ts-ignore
                    <SplitPane
                        split="vertical"
                        primary="second"
                        defaultSize={isShowCommentsPanel ? 280 : 0}
                        maxSize={isShowCommentsPanel ? 750 : 0}
                        minSize={isShowCommentsPanel ? 280 : 0}
                        paneStyle={{ overflow: 'auto' }}
                        resizerClassName={isShowCommentsPanel ? theme.resizer : theme.hideResizer}
                        pane2Style={{ borderLeft: '1px solid #d9d9d9' }}
                    >
                        <div
                            data-test="matrix-editor_container"
                            className={theme.tableScroll}
                            // onClick={() => !checkFocus() && onFocus()}
                        >
                            {
                                // @ts-ignore
                                <Table definition className={theme.table}>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell />
                                            {data!.columns.map((header: MatrixHeader) => {
                                                return (
                                                    <Table.HeaderCell key={header.orderNumber}>
                                                        <div className={theme.headerLane}>
                                                            {this.renderHeader(header, MatrixHeaderEnum.ColumnHeader)}
                                                        </div>
                                                    </Table.HeaderCell>
                                                );
                                            })}
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {data!.rows.map((header: MatrixHeader) => {
                                            return (
                                                <Table.Row key={header.orderNumber}>
                                                    <Table.HeaderCell>
                                                        <div className={theme.headerLane}>
                                                            {this.renderHeader(header, MatrixHeaderEnum.RowHeader)}
                                                        </div>
                                                    </Table.HeaderCell>
                                                    {this.getCellsByRowIndex(header.orderNumber)}
                                                </Table.Row>
                                            );
                                        })}
                                    </Table.Body>
                                </Table>
                            }
                        </div>
                        {comments}
                    </SplitPane>
                }
            </section>
        );
    }
}
const IntlComponent = injectIntl(MatrixEditor);

export { IntlComponent as MatrixEditor };
